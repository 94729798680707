import Hero from "./hero";

function Body() {
  return (
    <>
      <div className="flex justify-center">
        <Hero />
      </div>
    </>
  );
}

export default Body;
